import { X } from 'phosphor-react';
import React, { useMemo } from 'react';
import { NewTypeSelectData, NewTypeSelectValues } from '../types/select/newTypeOfSelect';
import { SelectedItemContainer, SelectedItemContainerHover } from './selectedItemContainer';
import { SelectItem } from '../types/select/common';

export const RenderSelectedItems = ({
    value: [key, value],
    data,
    handleChange,
    itemsFormattingFunction,
    displayLabel = true,
    secondary,
    isOpen,
    disableGroupMultiSelectedItems,
}: {
    data: NewTypeSelectData;
    handleChange: (value: NewTypeSelectValues[''], dataKey?: string) => void;
    value: [string, NewTypeSelectValues['']];
    itemsFormattingFunction?: (str: string) => string;
    displayLabel?: boolean;
    secondary?: boolean;
    isOpen: boolean;
    disableGroupMultiSelectedItems: boolean;
}) => {
    const displaySelectValue = useMemo(
        () =>
            value?.type === 'select' &&
            (data?.[key]?.data as SelectItem[])?.find(item => item.value === value?.value),
        [data, key, value?.type, value?.value],
    );

    const displayMultiSelectValue = useMemo(
        () =>
            data?.[key] &&
            value?.type === 'multiSelect' &&
            (data[key].data as SelectItem[])?.filter(item =>
                (value?.value as string[]).includes(typeof item === 'string' ? item : item.value),
            ),
        // ? [[[isAllSelected]]] lives only in multiSelect
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [data, key, value?.['isAllSelected'], value?.type, value?.value],
    );

    const shouldShowHoverCard =
        !!value?.value?.['isAllSelected'] || !disableGroupMultiSelectedItems;

    if (
        value?.type === 'multiSelect' &&
        Array.isArray(value?.value) &&
        displayMultiSelectValue?.length > 0
    ) {
        return (
            <div
                className="relative flex h-fit gap-1"
                key={typeof value === 'string' ? value : JSON.stringify(value)}
            >
                {(shouldShowHoverCard
                    ? (!disableGroupMultiSelectedItems && ['']) || ['All']
                    : displayMultiSelectValue || []
                )?.map(subItem => {
                    let label = typeof subItem === 'string' ? subItem : subItem?.label;
                    label = itemsFormattingFunction?.(label) ?? label;
                    return (
                        <SelectedItemContainerHover
                            disabled={isOpen}
                            key={key + label}
                            dropdownData={displayMultiSelectValue || []}
                            itemsFormattingFunction={itemsFormattingFunction}
                            title={key}
                            handleSelectAndMultiSelectChange={item => {
                                handleChange(
                                    {
                                        ...value,
                                        type: 'multiSelect',
                                        value: (value?.value as string[]).filter(
                                            xItem =>
                                                xItem !==
                                                (typeof item === 'string' ? item : item.value),
                                        ),
                                    },
                                    key,
                                );
                            }}
                            disableGroupMultiSelectedItems={disableGroupMultiSelectedItems}
                        >
                            <div>
                                <SelectedItemContainer secondary={secondary}>
                                    <span>
                                        {displayLabel &&
                                            (itemsFormattingFunction?.(key) ?? key) +
                                                (!disableGroupMultiSelectedItems ? '' : ': ')}
                                        {disableGroupMultiSelectedItems && label}
                                    </span>
                                    <button
                                        type="button"
                                        onClick={() => {
                                            handleChange(
                                                {
                                                    ...value,
                                                    type: 'multiSelect',
                                                    value:
                                                        value?.type === 'multiSelect' &&
                                                        (value?.isAllSelected ||
                                                            shouldShowHoverCard)
                                                            ? []
                                                            : (value?.value as string[]).filter(
                                                                  xItem =>
                                                                      xItem !==
                                                                      (typeof subItem === 'string'
                                                                          ? subItem
                                                                          : subItem.value),
                                                              ),
                                                },
                                                key,
                                            );
                                        }}
                                        aria-label="remove"
                                    >
                                        <X size={14} />
                                    </button>
                                </SelectedItemContainer>
                            </div>
                        </SelectedItemContainerHover>
                    );
                })}
            </div>
        );
    }
    if (value?.type === 'range') {
        const dataItem = data[key];
        return (
            <SelectedItemContainer
                secondary={secondary}
                key={`${key}: ${data[key]?.formateValueForDisplay?.(
                    value.value as (string | number)[],
                )}`}
            >
                <span>
                    {displayLabel && `${itemsFormattingFunction?.(key) ?? key}: `}
                    {data[key]?.formateValueForDisplay?.(value?.value as (string | number)[]) ??
                        (value?.value as number[])
                            ?.map(
                                item =>
                                    (dataItem?.type === 'range' &&
                                        dataItem?.numberFormate?.(item)) ||
                                    item,
                            )
                            ?.join('->')}
                </span>
                <button
                    type="button"
                    onClick={() => {
                        handleChange(
                            {
                                type: data[key].type,
                                value: undefined,
                            },
                            key,
                        );
                    }}
                    aria-label="remove"
                >
                    <X size={14} />
                </button>
            </SelectedItemContainer>
        );
    }
    // ? prevent rendering MultiSelect value here
    if (value?.type === 'select')
        return (
            <SelectedItemContainer
                secondary={secondary}
                key={typeof value === 'string' ? value : JSON.stringify(value)}
            >
                <span>
                    {displayLabel && `${itemsFormattingFunction?.(key) ?? key}: `}
                    {displaySelectValue?.['label'] ?? value?.value}
                </span>
                <button
                    type="button"
                    onClick={() => {
                        handleChange(
                            {
                                type: data[key].type,
                                value: undefined,
                            },
                            key,
                        );
                    }}
                    aria-label="Remove selected item"
                >
                    <X size={14} />
                </button>
            </SelectedItemContainer>
        );

    return null;
};
