import { Pagination as PaginationMantine, PaginationRootProps } from '@mantine/core';
import { PaginationIconProps } from '@mantine/core/lib/components/Pagination/Pagination.icons';
import classNames from 'classnames';
import { ArrowLeft, ArrowRight } from 'phosphor-react';
import React, { useCallback } from 'react';

function Pagination({
    total,
    page,
    onChange,
    className,
    styles,
}: {
    total: number;
    className?: string;
    page: number;
    onChange: (n: number) => void;
    styles?: PaginationRootProps['styles'];
}) {
    const NextIcon = useCallback(
        (props: PaginationIconProps) => (
            <div
                className={classNames(
                    props.className,
                    'flex items-center text-navy-solid-30 justify-center !text-xs gap-0.5 font-medium px-2 data-[disabled]:!text-gray-800',
                )}
            >
                <span>Next</span>
                <ArrowRight size={16} {...props} />
            </div>
        ),
        [],
    );
    const PrevIcon = useCallback(
        (props: PaginationIconProps) => (
            <div
                className={classNames(
                    props.className,
                    'flex items-center text-navy-solid-30 justify-center !text-xs font-medium px-2 gap-0.5 min-w-fit',
                )}
            >
                <ArrowLeft size={16} {...props} /> Previous
            </div>
        ),
        [],
    );
    return (
        <PaginationMantine
            total={total}
            value={page}
            classNames={{
                control:
                    'border-none !min-w-[19px] text-navy-solid-30 disabled:text-navy-solid-15 !border-transparent !bg-transparent data-[active]:!bg-risd-blue data-[active]:text-white hover:!bg-navy-solid-5 first:bg-transparent last:bg-transparent first:border-transparent last:border-transparent',
            }}
            nextIcon={NextIcon}
            className={className}
            previousIcon={PrevIcon}
            onChange={onChange}
            styles={styles}
        />
    );
}

export default Pagination;
