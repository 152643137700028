import { Menu, Title } from '@mantine/core';
import classNames from 'classnames';
import { Minus } from 'phosphor-react';
import React from 'react';
import Text from '../text';
import { SelectItem } from '../types/select/common';

export const SelectedItemContainerHover = ({
    children,
    disabled,
    dropdownData,
    title,
    handleSelectAndMultiSelectChange,
    disableGroupMultiSelectedItems,
    itemsFormattingFunction,
}: {
    children: JSX.Element;
    disabled?: boolean;
    dropdownData?: SelectItem[] | string[];
    title?: string;
    handleSelectAndMultiSelectChange: (item: SelectItem | string) => void;
    itemsFormattingFunction: (item: string) => string;
    disableGroupMultiSelectedItems: boolean;
}) => (
    <Menu withinPortal trigger="hover" disabled={disabled || disableGroupMultiSelectedItems}>
        <Menu.Target>{children}</Menu.Target>
        <Menu.Dropdown
            // eslint-disable-next-line tailwindcss/no-custom-classname
            className="glass-effect gradient-bg flex w-full !min-w-[200px] !max-w-[250px] rounded-lg border-0 border-l border-t border-border-color !bg-navy-solid-5"
        >
            <div className="flex w-full flex-col py-2">
                <div className="mb-2 flex gap-1 border-b border-border-color px-5 pb-2">
                    <Title order={5} className="!text-navy-solid-50">
                        {title}
                    </Title>
                    <Title order={5} className="!text-navy-solid-30">
                        ({dropdownData?.length} items)
                    </Title>
                </div>
                <div className="flex max-h-[220px] flex-col overflow-auto">
                    {dropdownData?.map(item => (
                        <button
                            key={`hover-card-${title}-${item?.label ?? item}`}
                            className="mx-2  flex items-center justify-between gap-1 whitespace-pre-wrap rounded px-3 py-2.5 text-left text-sm capitalize text-navy-solid-15 outline-none focus-within:!bg-risd-blue hover:!bg-risd-blue"
                            type="button"
                            onClick={e => {
                                handleSelectAndMultiSelectChange(item);
                            }}
                        >
                            <Text
                                type="14Light"
                                className="!font-normal !text-navy-solid-50 focus-within:!bg-risd-blue hover:!bg-risd-blue"
                            >
                                {item?.label ?? itemsFormattingFunction?.(item) ?? item}
                            </Text>
                            <Minus size={14} className="min-w-[14px] text-navy-solid-50" />
                        </button>
                    ))}
                </div>
            </div>
        </Menu.Dropdown>
    </Menu>
);

export const SelectedItemContainer = ({
    children,
    borderColor,
    secondary,
}: {
    children: React.ReactNode;
    borderColor?: string;
    secondary: boolean;
}) => (
    <div
        style={{
            borderColor,
            backgroundImage: secondary
                ? 'linear-gradient(91deg, #BB54B5 0%, #BB54B5aa 100%)'
                : 'linear-gradient(91deg, #466CF1 0%, #214EEC 100%)',
        }}
        className={classNames(
            'px-1.5 py-2 h-fit my-auto rounded capitalize font-medium text-xs flex gap-1 text-white whitespace-nowrap',
            {
                border: !!borderColor,
            },
        )}
    >
        {children}
    </div>
);
