import React, { FC, useCallback } from 'react';
import { RangeSlider, TextInput } from '@mantine/core';
import classNames from 'classnames';
import { Check, MagnifyingGlass } from 'phosphor-react';
import {
    NewSelectPossibleValues,
    NewTypeSelectData,
    NewTypeSelectValues,
    RangeInputDataType,
} from '../types/select/newTypeOfSelect';
import { grayScale, primaryColors } from '../../constants/colors';
import { replaceHtmlEntityNames } from '../util/replaceHtmlEntityNames';
import { SelectItem } from '../types/select/common';

export const DisplaySelectedMain: FC<{
    data: NewTypeSelectData;
    selectedMain: string;
    displayedData: SelectItem[];
    selectedSubItemRef: React.RefObject<HTMLButtonElement>[];
    handleChange: (value: NewTypeSelectValues[''], dataKey: string) => void;
    itemsFormattingFunction?: (str: string) => string;
    rangeInputState: [number, number];
    setRangeInputState: (value: [number, number]) => void;
    searchValue: string;
    setSearchValue: (value: string) => void;
    handleSelectAndMultiSelectChange: (
        item: SelectItem,
        key: string,
        isAllSelected?: boolean,
    ) => void;
    handleScroll: (e: React.UIEvent<HTMLDivElement, UIEvent>) => void;
    selectAll?: (items: SelectItem[], dataKey: string) => void;
    values: Record<string, NewSelectPossibleValues>;
    dropdownHeight: number;
    disableItemFocus?: boolean;
}> = ({
    data,
    selectedMain: key,
    displayedData,
    selectedSubItemRef,
    handleChange,
    itemsFormattingFunction,
    rangeInputState,
    setRangeInputState,
    searchValue,
    setSearchValue,
    handleSelectAndMultiSelectChange,
    handleScroll,
    selectAll,
    values,
    dropdownHeight,
    disableItemFocus,
}) => {
    const getSelectedItemsData = useCallback((item: string) => data?.[item], [data]);
    const isSelectAll = !!data?.[key]?.['isSelectAll'];

    const selectedItemsData = getSelectedItemsData(key);
    const selectedMainData = selectedItemsData?.data;
    if (!selectedItemsData?.type) return null;
    if (
        selectedMainData?.['length'] === 0 ||
        (typeof selectedMainData['max'] === 'number' &&
            (selectedMainData['max'] === 0 || isNaN(selectedMainData['max']))) ||
        (typeof selectedMainData['min'] === 'number' && isNaN(selectedMainData['min']))
    ) {
        return (
            <div className="mt-1 flex w-full items-center justify-center text-navy-solid-50">
                No options found
            </div>
        );
    }
    switch (selectedItemsData.type) {
        case 'range': {
            const rangeData = selectedMainData as RangeInputDataType['data'];
            return (
                <div className="w-full text-navy-solid-70">
                    <span className="whitespace-pre capitalize">
                        Filter {itemsFormattingFunction?.(key) ?? key}:
                    </span>
                    <RangeSlider
                        className="z-[300] mt-5 w-full self-end pl-4 pr-5 pt-2 outline-none"
                        labelAlwaysOn
                        defaultValue={[Number(rangeData.min), Number(rangeData.max)]}
                        classNames={{
                            label: 'bg-transparent font-bold !mt-2 text-navy-solid-50',
                            root: 'disabled:!cursor-not-allowed !px42',
                            trackContainer:
                                rangeData.min === rangeData.max ? '!cursor-not-allowed' : '',
                        }}
                        thumbFromLabel={
                            selectedItemsData?.formateValueForDisplay?.(rangeInputState)?.[0] ?? ''
                        }
                        thumbToLabel={
                            selectedItemsData?.formateValueForDisplay?.(rangeInputState)?.[1] ?? ''
                        }
                        disabled={rangeData.min === rangeData.max}
                        onChangeEnd={e => {
                            if (
                                Number(e[0]) === Number(rangeData.min) &&
                                Number(e[1]) === Number(rangeData.max)
                            ) {
                                handleChange(undefined, key);
                                setRangeInputState(e);
                                return;
                            }
                            setRangeInputState(e);
                            handleChange({ value: e, type: 'range' }, key);
                        }}
                        value={rangeInputState}
                        onChange={n => {
                            const max = n[1] > rangeData.max ? rangeData.max : n[1];
                            const min = n[0] < rangeData.min ? rangeData.min : n[0];
                            setRangeInputState([min, max]);
                        }}
                        label={n => selectedItemsData.numberFormate(n)}
                        styles={{
                            track: {
                                background: '#51606F',
                                color: '#51606F',
                                height: 6,
                            },
                            thumb: {
                                height: '14px',
                                width: '14px',
                                borderColor: grayScale[60],
                                background: grayScale[60],
                                borderWidth: '2.5px',
                                border: 0,
                            },
                        }}
                        min={Number(rangeData.min)}
                        max={Number(rangeData.max)}
                        step={rangeData?.steps ? rangeData.steps + 1 : 0.01}
                        minRange={0.1}
                        color={primaryColors.blue}
                    />
                    <div className="mt-1 flex justify-between text-xs">
                        <span>
                            {selectedItemsData.numberFormate(selectedItemsData.data.min)} (MIN)
                        </span>
                        <span>
                            {selectedItemsData.numberFormate(selectedItemsData.data.max)} (MAX)
                        </span>
                    </div>
                </div>
            );
        }
        default:
            return (
                <div className="flex w-full flex-col gap-1 overflow-visible ">
                    {selectedMainData?.['length'] >= 5 && (
                        <TextInput
                            leftSection={<MagnifyingGlass />}
                            placeholder="Search"
                            className="w-full"
                            classNames={{
                                root: 'border-b border-border-color border-dashed pb-2',
                                input: 'h-8 rounded border-none focus-visible:!border placeholder:text-navy-solid-30',
                                section: 'text-navy-solid-70',
                            }}
                            value={searchValue}
                            onChange={event => {
                                setSearchValue(event.currentTarget.value);
                            }}
                            variant="filled"
                            styles={{
                                input: {
                                    background: '#ffffff1A',
                                },
                            }}
                        />
                    )}
                    <div
                        onScroll={e => {
                            handleScroll(e);
                        }}
                        className="overflow-auto"
                        style={{ maxHeight: dropdownHeight - 60 }}
                    >
                        {displayedData.length > 1 && isSelectAll && (
                            <button
                                className="flex w-full gap-1 whitespace-pre-wrap rounded px-3 py-2.5 text-left text-sm capitalize text-navy-solid-70 outline-none focus-within:bg-risd-blue-10 hover:bg-risd-blue-10"
                                type="button"
                                ref={selectedSubItemRef[0]}
                                onClick={e => {
                                    e.stopPropagation();
                                    if (selectAll) selectAll(displayedData, key);
                                }}
                                onMouseEnter={e => {
                                    selectedSubItemRef[0].current?.focus();
                                }}
                            >
                                Select all
                            </button>
                        )}
                        {displayedData.map((item, i) => (
                            <button
                                ref={selectedSubItemRef[i + 1]}
                                key={`${item.value}-dropdown-item`}
                                className="z-[1] flex w-full gap-1 whitespace-pre-wrap rounded px-3 py-2.5 text-left text-sm capitalize text-navy-solid-70 outline-none focus-within:bg-risd-blue-10 hover:bg-risd-blue-10 disabled:!cursor-not-allowed disabled:!bg-transparent"
                                type="button"
                                onClick={e => {
                                    e.stopPropagation();
                                    handleSelectAndMultiSelectChange(item, key);
                                }}
                                disabled={item?.disabled}
                                onKeyDown={e => {
                                    if (
                                        e.key === 'ArrowDown' ||
                                        e.key === 'ArrowUp' ||
                                        e.key === 'Enter' ||
                                        e.key === 'Escape'
                                    ) {
                                        e.preventDefault();
                                    }

                                    if (e.key === 'Enter') {
                                        handleSelectAndMultiSelectChange(item, key);
                                    }
                                }}
                                onMouseEnter={e => {
                                    if (!disableItemFocus)
                                        selectedSubItemRef[i + 1].current?.focus();
                                }}
                            >
                                <Check
                                    weight="bold"
                                    className={classNames('min-w-[20px]', {
                                        // ? Selected
                                        'text-navy-solid-70':
                                            selectedItemsData.type === 'multiSelect'
                                                ? (values?.[key]?.value as string[])?.includes(
                                                      item.value,
                                                  ) || values?.[key]?.['isAllSelected']
                                                : item.value === values?.[key]?.value,
                                        'text-transparent':
                                            selectedItemsData.type === 'multiSelect'
                                                ? !(values?.[key]?.value as string[])?.includes(
                                                      item.value,
                                                  )
                                                : item.value !== values?.[key]?.value,
                                    })}
                                    size={20}
                                />
                                {replaceHtmlEntityNames(item?.label)}
                            </button>
                        ))}
                    </div>
                </div>
            );
    }
};
