import React, { useCallback, useEffect, useRef, useState } from 'react';
import { SelectItem } from '../andromeda/types/select/common';

const scrollNoManipulationLimit = 500;
const scrollLimit = 250;
const scrollAddingLimit = 150;

// ? Because splice was acting weirdy!
const getData = ({ data, e, s }: { data: SelectItem[]; s: number; e: number }) =>
    [...data].reduce((acc, item, i) => (s <= i && i <= e ? [...acc, item] : acc), []);

function useScroll({
    data,
    isSelectOpen,
    selectedItemIndex = 0,
}: {
    data: SelectItem[];
    isSelectOpen: boolean;
    selectedItemIndex?: number;
}) {
    const dataRef = useRef([]);
    const displayedDataRef = useRef({
        data: [],
        boundaries: { start: 0, end: 0 },
    });
    const selectedItemRef = useRef(data[selectedItemIndex]);
    const [displayedData, setDisplayedData] = useState<typeof data>([]);
    const [start, setStart] = useState<number>(0);
    const [end, setEnd] = useState<number>(0);

    const dataShrinker = useCallback(() => {
        if (!data || !isSelectOpen) return;
        const index = selectedItemIndex > 0 ? selectedItemIndex : 0;
        if (
            start < end &&
            (end <= index || index >= start) &&
            data.length === dataRef.current.length &&
            selectedItemRef.current === data?.[index]
        )
            return;

        dataRef.current = data;
        selectedItemRef.current = data[index];

        if (data.length < scrollNoManipulationLimit) {
            setDisplayedData(data);
            setStart(0);
            setEnd(data.length);
            displayedDataRef.current.boundaries = {
                start: 0,
                end: data.length,
            };
            displayedDataRef.current.data = data;
            return;
        }

        const s = index - scrollLimit < 0 ? 0 : index - scrollLimit;
        const e = data.length <= index + scrollLimit ? data.length : index + scrollLimit;
        const dataSet = [...data].reduce(
            (acc, item, i) => (s <= i && i <= e ? [...acc, item] : acc),
            [],
        );
        setStart(s);
        setEnd(e);
        displayedDataRef.current.boundaries = {
            start: s,
            end: e,
        };
        displayedDataRef.current.data = dataSet;

        setDisplayedData(dataSet);
        displayedDataRef.current.data = dataSet;
    }, [data, end, isSelectOpen, selectedItemIndex, start]);

    useEffect(() => {
        dataShrinker();
    }, [dataShrinker, data]);

    useEffect(() => {
        if (isSelectOpen) return;
        if (displayedDataRef.current.data?.length > 0) {
            setDisplayedData(displayedDataRef.current.data);
            setStart(displayedDataRef.current.boundaries.start);
            setEnd(displayedDataRef.current.boundaries.end);
        }
    }, [isSelectOpen]);

    const handleScroll = useCallback(
        (e: React.UIEvent<HTMLDivElement>) => {
            if (!isSelectOpen) return;
            // ? If we don't have large amount of data we don't need it
            if (!data) return;
            if (data.length < scrollNoManipulationLimit) {
                setDisplayedData(data);
            }
            const progress =
                ((e.currentTarget.scrollTop + e.currentTarget.clientHeight) /
                    e.currentTarget.scrollHeight) *
                100;

            if (progress >= 90 && end < data.length) {
                const newEnd =
                    data.length > end + scrollAddingLimit ? end + scrollAddingLimit : data.length;
                setDisplayedData(getData({ s: start, e: newEnd, data }));
                setEnd(newEnd);
            } else if (progress <= 10 && start > 0) {
                const startLeft = start > scrollAddingLimit ? start - scrollAddingLimit : 0;
                setDisplayedData(getData({ s: startLeft, e: end, data }));
                setStart(startLeft);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [data, displayedData, end, isSelectOpen, start],
    );
    return {
        displayedData,
        handleScroll,
    };
}

export { useScroll };
